@use '@angular/material' as mat;
@include mat.core();

$mat-vitu-blue: (
  50: #4FC8EC,
  100: #4FC8EC,
  200: #4FC8EC,
  300: #4FC8EC,
  400: #4FC8EC,
  500: #4FC8EC,
  600: #4FC8EC,
  700: #4FC8EC,
  800: #4FC8EC,
  900: #4FC8EC,
  A100: #4FC8EC,
  A200: #4FC8EC,
  A400: #4FC8EC,
  A700: #4FC8EC,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$candy-app-primary: mat.define-palette($mat-vitu-blue);
$candy-app-accent:  mat.define-palette($mat-vitu-blue);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat.define-palette(mat.$red-palette);

$candy-app-theme: mat.define-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn,
  )
));

@include mat.all-component-themes($candy-app-theme);

@import url("./assets/fonts/gotham/hco_fonts.css");
@import url("./assets/fonts/fontawesome-pro/css/all.css");
@import "./_variables.scss";

*, *::before, *::after {
  box-sizing: border-box;
}

html, body { min-height: 100%; }
body {
  color: #3e3f3e;
  background-color: $bg-body;
  margin: 0;
  font-family: $font-family-gotham;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.content {
  background-color: #fff;
  padding: 1px 5.7% 0 4.2%;
  margin: 0 auto;
  max-width: $content-width;
  @include media("max", "md") {
    padding: 1px 4% 0;
  }

  &_allowed {
    color: $vitu-blue-1;
    min-height: 570px;
    @include media("max", "md") {
        min-height: 500px;
    }

    .content-head {
        font-weight: 700;
        text-transform: uppercase;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: $letter-spacing-md;
        line-height: 25px;
        margin: 0;
    }
  }

  &_loading {
      display: flex;
      align-items: center;
      justify-content: center;
  }

  &-head {
    color: $vitu-blue-1;
    font-size: 48px;
    font-weight: 300;
    line-height: 58px;
    margin: 60px 0 23px;
    letter-spacing: -0.03em;
    @include media("max", "md") {
        font-size: 25px;
        margin-top: 50px;
        margin-bottom: 25px;
        line-height: 30px;
    }
  }

  &-wrap-col {
    @include flexbox(flex-start, space-between);
    @include media("max", "lg") {
        flex-wrap: wrap;
    }
  }
}

.row {
    margin: 0 -15px;
    display: flex;
}

[class^="col-"] {
    padding: 0 15px;
}

.col-4 {
    width: 25%;
}

.section {
  margin-bottom: 30px;
  @include media("max", "md") {
      margin-bottom: 25px;
  }

  &.pay-card {
    margin-bottom: 15px;
  }

  &_title {
    color: $vitu-blue-1;
    margin-bottom: 17px;
    @include flexbox(center);

    span {
      background-color: #55C7E9;
      border-radius: 50%;
      color: #fff;
      font-family: $font-family-roboto;
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;
      display: inline-block;
      height: 24px;
      width: 24px;
      margin-right: 14px;
      @include flexbox(center, center);
    }

    h3 {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      letter-spacing: $letter-spacing-md;
    }
  }

  .mat-radio-group {
    margin-left: 42px;
    @include media("max", "md") {
        margin-left: 38px;
    }
  }

  &__desc {
    background-color: $bg-summary;
    border-radius: 4px;
    color: #70757A;
    padding: 0;
    font-family: $font-family-gotham;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 15px;

    a {
      color: $vitu-blue-1;
    }

    p {
      margin: 0;
    }

    &_footer {
      position: relative;
      margin-top: 15px;
    }

    &-transparent {
      background-color: transparent;
      padding-top: 16px;
    }
  }

  &__accept-terms {
    margin-bottom: 65px;

    .mat-checkbox-label {
        color: #70757A;
        font-size: 12px;
        font-family: $font-family-gotham;
        line-height: 16px;
    }

    .mat-checkbox-inner-container {
        margin: 0;
        margin-right: 8px;
    }

    .mat-checkbox-layout {
        align-items: flex-start;
        white-space: normal;
    }

    a {
        color: #4FC8EC;
        font-weight: 700;
    }
  }

  &-mb-0 {
    margin-bottom: 0;
  }

  &_item-mb {
    margin-top: 10px;
    margin-bottom: 27px;
  }

}

.mat-radio-button {
  & + & {
    margin-left: 40px;
    @include media("max", "md") {
        margin-left: 25px;
    }
  }
}

.mat-radio-label-content {
  color: $vitu-blue-1;
  font-size: 18px;
  font-weight: 300;
  font-family: $font-family-gotham;
  letter-spacing: $letter-spacing-md;
}

.mat-button-base {

  & + & {
    margin-left: 30px;
  }
}

.mat-form-field {
    font-family: $font-family-gotham;
}

.full-width {

  .mat-form-field {
    font-family: $font-family-gotham;
    width: 100%;
  }
}

.clover-footer {
  display: none;
}

.section.credit-card iframe {
  margin-top: -6px;
  height: 66px !important;
}

.mat-select,
.mat-option {
  font-family: $font-family-gotham;
}

.mat-select-trigger {
  height: auto !important;
}

.modal-action {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;

    .mat-button-base {
        width: auto;
    }
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: $vitu-blue-1;
}

.risk-declined-modal {
    max-height: 95vh;

    .mat-dialog-container {
        padding: 0;
    }
}
