$sizes: ("xs":320px, "sm":576px, "md":768px, "lg":992px, "xlg":1160px, "xl":1330px);

@mixin media($minmax, $media) {
    @each $size, $resolution in $sizes {
      @if $media == $size {
        @media only screen and (#{$minmax}-width: $resolution) {
          @content;
        }
      }
    }
}

@mixin flexbox($align-items: flex-start, $justify-content: flex-start, $direction: row) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $direction;
}

$vitu-blue-1: #013D4F;
$vitu-blue-2: #073E4D;
$bg-body: #F3F6F5;
$bg-summary: #F0FCFF;

$color-red-base: #EA3D2F;
$color-green-base: #2FA84F;

$font-family-roboto: Roboto, sans-serif;
$font-family-gotham: 'HCo Gotham SSm', sans-serif;
$letter-spacing-md: 0.25px;

$content-width: 1445px;
